<template>
  <div class="pageview">
    <!-- <van-nav-bar title="社区服务" fixed /> -->
    <!-- banner切换 -->
    <div class="">
      <van-swipe class="homebanner" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="banner in bannerImgs" :key="banner.index"
          ><img :src="banner"
        /></van-swipe-item>
      </van-swipe>
    </div>
    <h3 class="grouptitle">吉华惠企服务平台</h3>
    <van-grid class="wktype" :border="false" :column-num="5">
      <van-grid-item icon="photo-o" @click="gotoUrl('/home/dynamic')">
        <van-image
          class="iconimg"
          :src="require('../../assets/homej.png')"
        ></van-image>
        <span class="wktext">园区动态</span>
      </van-grid-item>
      <van-grid-item icon="photo-o" @click="gotoUrl('/home/huayang')">
        <van-image
          class="iconimg"
          :src="require('../../assets/zhongjie.png')"
        ></van-image>
        <span class="wktext">中介超市</span>
      </van-grid-item>
      <van-grid-item icon="photo-o" @click="gotoUrl('/home/serve')">
        <van-image
          class="iconimg"
          :src="require('../../assets/shequhuodong.png')"
        ></van-image>
        <span class="wktext">资源看板</span>
      </van-grid-item>
      <van-grid-item icon="photo-o" @click="gotoUrl('/home/classroom')">
        <van-image
          class="iconimg"
          :src="require('../../assets/dangan.png')"
        ></van-image>
        <span class="wktext">知识讲堂</span>
      </van-grid-item>
      <template>
        <van-grid-item icon="photo-o" @click="gotoUrl('/home/interaction')">
          <van-image
            class="iconimg"
            :src="require('../../assets/hudong.png')"
          ></van-image>
          <span class="wktext">区企互动</span>
        </van-grid-item>
      </template>
    </van-grid>
    <h3 class="grouptitle">
      园区动态
      <router-link to="/home/dynamic" class="more">
        <span>更多<van-icon name="arrow" /></span>
      </router-link>
    </h3>
    <div class="information">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in NoticeList" :key="item.index">
          <div class="center">
            <div @click="gotoUrl('/home/classroom/particulars/' + item.GNId)">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div
                  style="display: inline-block"
                  class="ContentA"
                  v-if="item.ThemeImg"
                >
                  <img :src="item.ThemeImg" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssueTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <van-empty description="暂无动态" v-if="!NoticeList.length" />
    </div>
    <div style="padding-bottom: 5px; background: #fafafa">
      <h3 class="grouptitle">
        企业资讯<router-link to="/home/introduce" class="more">
          <span>更多<van-icon name="arrow" /></span>
        </router-link>
      </h3>
      <div class="information">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item in NoticeLists" :key="item.index">
            <div class="center">
              <div @click="gotoUrl('/home/classroom/particulars/' + item.GNId)">
                <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
                <div class="Content">
                  <div style="display: inline-block" class="ContentA">
                    <img v-if="item.ThemeImg" :src="item.ThemeImg" alt="" />
                  </div>
                  <div style="display: inline-block" class="ContentB">
                    <span class="van-multi-ellipsis--l3">{{
                      item.Summary
                    }}</span>
                    <br />
                    <span class="centertime">{{ item.IssueTime }}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
        <van-empty description="暂无资讯" v-if="!NoticeLists.length" />
      </div>
    </div>
    <!-- 登录按钮 -->
    <!-- <div class="flexOn" @click="onClickRight()">企业登录</div> -->
  </div>
</template>

<script>
import config from "@/config/index";
// import { mapGetters } from 'vuex'
import { WeGetEntElementPage, WeGetGoodNewsPage } from "@/api/Hyapi";
import { WeGetRealInfoPage } from "@/api/RealInfo";
import { WeGetAppletInfo } from "@/api/index";
// import TipsMiniProject from "@/components/TipsMiniProject";
// import Tabbar from "@/components/Tabbar";
import axios from "axios";
import { setOpenId, getOpenId, getaccToken, setaccToken } from "@/utils/auth";

export default {
  // computed: {
  //   ...mapGetters([
  //     'openId',
  //   ])
  // },
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data() {
    return {
      appInfo: {},
      bannerImgs: [],
      active: 0,
      NoticeList: [],
      NoticeLists: [],
      area: "",
      AgencyFrom: {
        page: 1,
        limit: 5,
      },
      listfrom: {
        page: 1,
        limit: 5,
      }, //查询传参
      AgencyList: [],
      arrimg: [],
    };
  },
  created() {
    this.getAppInfo();
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
    this.area = config.area;
    this.getNoticeList();
    this.getNoticeLists();

    this.$WexinShare(location.href, {
      title: "欢迎来到吉利华阳"
    });
  },
  methods: {
    // 跳转登录
    onClickRight() {
      if (getaccToken() != undefined) {
        this.$router.push({ name: "firm" });
      } else {
        this.$router.push("/huayang/firmlogin");
      }
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    //小程序跳转到小程序
    gotominiProgram(name) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/openminip?name=" + name,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 园区动态
    getNoticeList() {
      // 分享
      // console.log(this);
      // fenxiang(this.location.href);
      // fenxiang("http://172.18.1.229:8080/");
      this.listfrom.group = 2;
      WeGetGoodNewsPage(this.listfrom).then((res) => {
        this.NoticeList = res.data.data;
        for (let i = 0; i < this.NoticeList.length; i++) {
          this.NoticeList[i].IssueTime = this.dataFormat(
            this.NoticeList[i].IssueTime
          );
        }
      });
    },
    // 园区资讯
    getNoticeLists() {
      this.listfrom.group = 3;
      WeGetGoodNewsPage(this.listfrom).then((res) => {
        this.NoticeLists = res.data.data;
        for (let i = 0; i < this.NoticeLists.length; i++) {
          this.NoticeLists[i].IssueTime = this.dataFormat(
            this.NoticeLists[i].IssueTime
          );
          if (this.NoticeLists[i].ThemeImg == "") {
            this.NoticeLists[i].ThemeImg =
              "https://jlhyapi.dexian.ren//upload/images/2021/11/c29730eb-14e.png";
          }
        }
      });
    },
    // 小程序展示数据
    getAppInfo() {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          var imgs = [];
          imgs[0] = list.data.SysPic1Url;
          imgs[1] = list.data.SysPic2Url;
          imgs[2] = list.data.SysPic3Url;
          this.bannerImgs = imgs;
          this.appInfo = list.data;
        }
      });
    },
    // 资源看板
    // GetAgencyPage() {
    //   WeGetEntElementPage(this.AgencyFrom).then((res) => {
    //     this.AgencyList = res.data.data;
    //     for (let i = 0; i < this.AgencyList.length; i++) {
    //       this.AgencyList[i].IssueTime = this.dataFormat(
    //         this.AgencyList[i].IssueTime
    //       );
    //     }
    //   });
    // },
    // 时间格式化
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
};
</script>

<style scoped>
.pageview {
  background-color: #fafafa;
  height: 100%;
}
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.wktime {
  padding: 0 20px;
}
.wktype {
  margin: 10px 0px;
}
.wktype .van-grid-item {
  background: red;
}
.orginview .iconimg {
  width: 62%;
}
.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 12px;
}
.grouptitle {
  border-left: 4px solid #e8060b;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px 0 10px;
  color: #000;
}
.center {
  background: #fff;
  width: 82%;
  margin: 15px 5%;
  margin-bottom: 5px;
  border-radius: 10px;
  box-shadow: 1px 1px 5px #e6e6e6;
}
.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
}
.swipeBox {
  position: relative;
  width: 90%;
  padding: 5px 0;
  margin: 0px 5%;
  padding-bottom: 30px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 1px 1px 5px #e6e6e6;
}
.swipeBox div:nth-child(1) {
  padding-left: 15px;
  color: #000;
}
.swipeBox .contains {
  display: flex;
  width: 100%;
  font-size: 14px;
  margin: 5px 15px;
}
.contains span {
  color: #333;
  width: 75%;
}
.swipeBox .iconNew {
  position: absolute;
  top: 0px;
  right: 10px;
}
.swipeBox .iconPhone {
  position: absolute;
  top: 70px;
  right: 15px;
}
.flexOn {
  z-index: 10;
  right: 15px;
  bottom: 30px;
  position: fixed;
  width: 75px;
  height: 75px;
  line-height: 75px;
  background: #2196f3;
  border-radius: 15px;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
.more span {
  float: right;
  /* margin-right: 30px; */
  font-size: 12px;
  color: #2196f3;
}
.more span .van-icon {
  top: 2px;
  right: 25px;
}
</style>